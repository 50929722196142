@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@1&display=swap);
body {
  margin: 0;
  width: 100vw;
  height: auto !important;
  overflow: auto;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background: #ffafbd; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#ffc3a0),
    to(#ffafbd)
  );
  background: linear-gradient(
    to right,
    #ffc3a0,
    #ffafbd
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
/* ANT MODAL */
.ant-modal-footer {
  display: none;
}
.ant-modal-body {
  padding: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.card {
  background: rgba(250, 250, 250, 0.8) !important;
}
.home-container .generated-poem-card .card {
  max-height: 351px;
}

.ant-modal-body .generate-poem-card-line {
  font-size: 16px !important;
}
.home-container {
  margin: 0 auto;
  max-width: 1000px;
}
nav {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  box-shadow: 3px 3px 30px lightgray;
  border-radius: 0px 20px 20px 0px;

  background: #fff; /* fallback for old browsers */
}
.mobile-menu-container button {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
}
ul,
li {
  margin: 0;
  padding: 0;
}

ul {
  padding: 25px;
  position: absolute;
  top: 100px;
  width: 230px;
}

li {
  list-style: none;

  display: flex;
  align-items: center;
  cursor: pointer;
}
.mobile-menu-container {
  z-index: 999999;
}

.icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1 1;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* CARD */

.card-body {
  max-width: 100%;
  height: auto;
}

/* LOADING */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  top: 50px;
}
.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 40px 40px;
          transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: -webkit-gradient(linear, left bottom, left top, from(#6e48aa), to(#9d50bb));
  background: linear-gradient(to top, #6e48aa, #9d50bb);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@-webkit-keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* MEDIA QUERIES */

@media screen and (max-width: 600px) {
  .home-container .generated-poem-card .card {
    max-height: 551px;
    margin: 80px 20px 20px 20px;
  }
}

